import { FC } from 'react';
import { ProgramPagesBlocks } from '../../../../../types/strapi/component/blocks/program-pages-block';
import { twoColumnComponent } from '../../../../../types/strapi/component/blocks/two-column-block';
import { TwoColumnDisplayBlock } from '../../../organisms/two-column/blocks/two-column-block';
import { keypointsOdtComponent } from '../../../../../types/strapi/component/blocks/keypoints-odt';
import { DownloadableDocumentsODTDisplayBlock } from '../../../organisms/downloadable-document-odt-block/downloadable-document-odt-block';
import './program-and-project-content-dynamic-zone.scss';
import { IntroductionODTPPDisplayBlock } from '../../../organisms/introduction-odt-pp/introduction-odt-pp-block';
import { introductionODTPPComponent } from '../../../../../types/strapi/component/blocks/introduction-odt-pp';
import { toolsOdtComponent } from '../../../../../types/strapi/component/blocks/tools-odt';
import { ToolsOdtDisplayBlock } from '../../../organisms/tools-odt/tools-odt-block';
import { videoODTComponent } from '../../../../../types/strapi/component/blocks/video-odt';
import VideosODTBlock from '../../../organisms/video-odt/video-odt-block';
import { verticalStepperComponent } from '../../../../../types/strapi/component/blocks/vertical-stepper';
import { VerticalStepperDisplayBlock } from '../../../organisms/vertical-stepper/vertical-stepper';
import { callToActionOdtComponent } from '../../../../../types/strapi/component/blocks/call-to-action-odt';
import { CallToActionOdtDisplayBlock } from '../../../organisms/call-to-action-odt/call-to-action-odt-block';
import { indicatorsOdtComponent } from '../../../../../types/strapi/component/blocks/indicators-odt';
import { IndicatorsOdtDisplayBlock } from '../../../organisms/indicators-odt/indicators-odt-block';
import { domainsListComponent } from '../../../../../types/strapi/component/blocks/domainsList';
import { DomainsListDisplayBlock } from '../../../organisms/domains-list/blocks/domains-list-block';
import { dataDomainComponent } from '../../../../../types/strapi/component/blocks/dataDomain';
import { DataDomainDisplayBlock } from '../../../organisms/data-domain/blocks/data-domain-block';
import { keyPeopleComponent } from '../../../../../types/strapi/component/blocks/key-people';
import { KeyPeopleDisplayBlock } from '../../../organisms/key-people-block/key-people-block';
import { downloadableDocumentODTComponent } from '../../../../../types/strapi/component/blocks/downloadable-document-odt';
import { KeypointsOdtDisplayBlock } from '../../../organisms/keypoints-odt/keypoints-odt-block';

interface ProgramPagesDynamicZoneProps {
  blocks: ProgramPagesBlocks[];
}

const ProgramAndProjectDynamicZone: FC<ProgramPagesDynamicZoneProps> = ({ blocks }) => {
  return (
    <div className="ppcdz-size">
      {blocks.map((block: ProgramPagesBlocks) => {
        switch (block.__component) {
          case introductionODTPPComponent.literal:
            return <IntroductionODTPPDisplayBlock introductionODTPPBlock={block} />;
          case keypointsOdtComponent.literal:
            return <KeypointsOdtDisplayBlock keypointsBlock={block} />;
          case verticalStepperComponent.literal:
            return <VerticalStepperDisplayBlock verticalStepperBlock={block} />;
          case toolsOdtComponent.literal:
            return <ToolsOdtDisplayBlock toolsBlock={block} />;
          case twoColumnComponent.literal:
            return <TwoColumnDisplayBlock twoColumnBlock={block} />;
          case videoODTComponent.literal:
            return <VideosODTBlock videoData={block} />;
          case callToActionOdtComponent.literal:
            return <CallToActionOdtDisplayBlock callToAtionBlock={block}/>;
          case indicatorsOdtComponent.literal:
            return <IndicatorsOdtDisplayBlock indicatorsBlock={block} isSmallOnly={true}/>;
          case domainsListComponent.literal:
            return <DomainsListDisplayBlock domainsListBlock={block} isSmallOnly={true}/>;
          case dataDomainComponent.literal:
            return <DataDomainDisplayBlock dataDomainBlock={block} isSmallOnly={true}/>;
          case keyPeopleComponent.literal:
            return <KeyPeopleDisplayBlock keyPeopleBlock={block}/>;
          case downloadableDocumentODTComponent.literal:
            return <DownloadableDocumentsODTDisplayBlock documentsBlock={block}/>;
          default:
            return <p>{block}</p>;
        }
      })}
    </div>
  );
};

export { ProgramAndProjectDynamicZone };
