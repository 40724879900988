import { FC, useState } from 'react';
import './key-people-contact.scss';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { Contact } from '../../../../types/strapi/contact/contact';
import { getStrapiImage, IMG_FORMATS } from '../../../../utils/image';

interface KeyPeopleContactProps {
  contact: Contact;
}

const capitalizeWords = (str: string) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};


const KeyPeopleContact: FC<KeyPeopleContactProps> = ({ contact }) => {
  const { avatar, email, name, role } = contact.attributes;
  const [displaybutton, setDisplaybutton] = useState(false);
  return (
    <div
      className="kp-contact-container"
      onMouseEnter={() => {
        setDisplaybutton(true);
      }}
      onMouseLeave={() => {
        setDisplaybutton(false);
      }}
    >
      <img
        src={getStrapiImage(avatar.data.attributes, IMG_FORMATS.large)}
        alt="key people avatar"
        className="contact-avatar"
      />
      <h6 className="ago-principal-title-bold">{capitalizeWords(name)}</h6>
      <p className="ago-text-regular-16">{capitalizeWords(role)}</p>
      {email ?
        <NJButton
          className={`contact-btn ${displaybutton ? 'visible' : ''}`}
          href={`mailto:${email}`}
          icon="email"
          label="Contact"
          size="normal"
          variant="primary"
        /> : <div className='kp-button-empty-space' />
      }
    </div>
  );
};

export { KeyPeopleContact };
