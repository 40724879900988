import { literal, number, object, string } from 'idonttrustlikethat';
import { contactsResponse } from '../../contact/contact';

const keyPeopleComponent = literal('blocks.key-people');
const keyPeopleBlock = object({
  id: number,
  __component: keyPeopleComponent,
  title: string.nullable(),
  contacts: contactsResponse
});

type KeyPeopleBlock = typeof keyPeopleBlock.T;
type KeyPeopleComponent = typeof keyPeopleComponent.T;

export { keyPeopleBlock, keyPeopleComponent };
export type { KeyPeopleBlock, KeyPeopleComponent };

