import { FC } from 'react';
import { DataFetcher } from '../../components/atomic/templates/data-fetcher';
import { referalApiWS } from '../../config/unsplash';
import StrapiRoutes from '../../services/strapi/strapiRoutes';
import { connect } from 'redux-bundler-react';
import { ProgramPagesRouteInfo } from '../../types/routing';
import { programMainPagesResponce } from '../../types/strapi/program-pages/program-pages';
import { programAndProjectPagesResponce } from '../../types/strapi/program-pages/content-program-page/content-program-page';
import { ProgramPagesValidator } from '../../components/atomic/templates/program-pages/program-pages-validator';
import { getParamLastSlug } from '../../components/atomic/templates/program-pages/utils/get-param-last-slug';

interface propTypes {
  routeInfo: ProgramPagesRouteInfo;
}

const ProgramPages: FC<propTypes> = ({ routeInfo }) => {
  return (
    <DataFetcher
      {...{
        route: `${referalApiWS}/api/${StrapiRoutes.programPages}`,
        rawparams: `&filters[slug][$eq]=${routeInfo.params.slug}`,
        params: {
          populate: [
            'menu',
            'menu.projects',
            'menu.projects.subProjects',
            'header',
            'header.background',
            'header.backgroundSmall',
          ],
        },
        validator: programMainPagesResponce,
      }}
    >
      {(dataProgramPages) => (
        <DataFetcher
          {...{
            route: `${referalApiWS}/api/${StrapiRoutes.programPagesContent}`,
            rawparams: `&filters[slug][$eq]=${getParamLastSlug(routeInfo)}`,
            params: {
              populate: [
                'blocks',
                'blocks.image',
                'blocks.picto',
                'blocks.iconDownload',
                'blocks.buttonIcon',
                'blocks.keypoints',
                'blocks.keypoints.Picto',
                'blocks.tools',
                'blocks.tools.picto',
                'blocks.video_links',
                'blocks.video_links.video_caption',
                'blocks.steps',
                'blocks.button',
                'blocks.indicators_list',
                'blocks.domains',
                'blocks.domains.background',
                'blocks.domain',
                'blocks.domain.background',
                'blocks.domain.attachements',
                'blocks.contacts',
                'blocks.contacts.avatar',
                'blocks.documents',
                'blocks.documents.localDocument',
                'blocks.documents.iconDownload',
              ],
            },
            validator: programAndProjectPagesResponce,
          }}
        >
          {(dataContentPages) => (
            <ProgramPagesValidator
              programPage={dataProgramPages}
              pageContent={dataContentPages}
              routeInfo={routeInfo}
            />
          )}
        </DataFetcher>
      )}
    </DataFetcher>
  );
};

export default connect('selectRouteInfo', ProgramPages);
