import { union } from 'idonttrustlikethat';
import { twoColumnBlock, twoColumnComponent } from './two-column-block';
import { keypointsOdtBlock, keypointsOdtComponent } from './keypoints-odt';
import {
  introductionODTPPBlock,
  introductionODTPPComponent,
} from './introduction-odt-pp';
import { toolsOdtBlock, toolsOdtComponent } from './tools-odt';
import { videoODTBlock, videoODTComponent } from './video-odt';
import { verticalStepperBlock, verticalStepperComponent } from './vertical-stepper';
import { callToActionOdtBlock, callToActionOdtComponent } from './call-to-action-odt';
import { indicatorsOdtBlock, indicatorsOdtComponent } from './indicators-odt';
import { domainsListBlock, domainsListComponent } from './domainsList';
import { dataDomainBlock, dataDomainComponent } from './dataDomain';
import { keyPeopleBlock, keyPeopleComponent } from './key-people';
import { downloadableDocumentODTBlock, downloadableDocumentODTComponent } from './downloadable-document-odt';

const programPagesBlocksComponents = union(
  twoColumnComponent,
  introductionODTPPComponent,
  keypointsOdtComponent,
  toolsOdtComponent,
  videoODTComponent,
  verticalStepperComponent,
  callToActionOdtComponent,
  indicatorsOdtComponent,
  domainsListComponent,
  dataDomainComponent,
  keyPeopleComponent,
  downloadableDocumentODTComponent
);
const programPagesBlocks = union(
  twoColumnBlock,
  introductionODTPPBlock,
  keypointsOdtBlock,
  toolsOdtBlock,
  videoODTBlock,
  verticalStepperBlock,
  callToActionOdtBlock,
  indicatorsOdtBlock,
  domainsListBlock,
  dataDomainBlock,
  keyPeopleBlock,
  downloadableDocumentODTBlock
);

type ProgramPagesBlocks = typeof programPagesBlocks.T;
type ProgramPagesComponents = typeof programPagesBlocksComponents.T;

export { programPagesBlocks, programPagesBlocksComponents };
export type { ProgramPagesBlocks, ProgramPagesComponents };
