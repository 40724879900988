import { FC } from 'react';
import './key-people-block.scss';
import { KeyPeopleBlock } from '../../../../types/strapi/component/blocks/key-people';
import { KeyPeopleContact } from '../../molecules/key-people-contact/key-people-contact';

interface KeyPeopleBlockProps {
  keyPeopleBlock: KeyPeopleBlock;
}

const KeyPeopleDisplayBlock: FC<KeyPeopleBlockProps> = ({ keyPeopleBlock }) => {
  const length = keyPeopleBlock.contacts.data.length > 4 ? 4 : keyPeopleBlock.contacts.data.length
  const smallLength = keyPeopleBlock.contacts.data.length > 3 ? 3 : keyPeopleBlock.contacts.data.length
  return (
    <div className="kp-container" style={
      {
          '--key-people-length': `${length}`,
          '--key-people-length-small': `${smallLength}`,
      } as React.CSSProperties
    }>
      {keyPeopleBlock.title && <h1 className="ago-sub-tera-title kp-title">{keyPeopleBlock.title}</h1>}
      <div className="kp-list-container">
        <div className="kp-list">
          {keyPeopleBlock.contacts.data.map((contact) => (
            <KeyPeopleContact key={contact.id} contact={contact} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { KeyPeopleDisplayBlock };